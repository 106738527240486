import React, {useContext, useMemo} from 'react';
import {ConfigurationContext} from "../../../components/configuration-utils";
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {MakeModelResource} from "../../../rest/APIResources";
import {useFormState} from "react-final-form";
import {sortNumerical} from "./sorting";
import {useTranslation} from 'react-i18next';
import {reduceToName} from "./year-select";
import union from "lodash/union";

const filterVehicles = (vehiclesAll, model) => {
    if (typeof vehiclesAll?.model_list !== "string") {
        const series = vehiclesAll?.model_list.filter(current => current.name === model)[0];
        if (series) {
            return series.series_list.reduce(reduceToName, []) ?? [];
        }
    }
};

const VersionApiSelect = () => {
    const {values: {year, brand, model}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();

    const makeModel = useResource(MakeModelResource.detailShape(), {year, brand});

    const versions = useMemo(() => {
        if (model && model !== "") {
            const {savedVehicles, yearVehicles} = makeModel;
            const modelSaved = filterVehicles(savedVehicles, model);
            const modelApi = filterVehicles(yearVehicles, model);
            return union(modelSaved, modelApi).sort(sortNumerical);
        }
        return []
    }, [makeModel, model]);

    return <BsFormSelect name={'version'} placeholder={t('series')} options={versions}/>
};

const VersionSelect = () => {
    const {progress} = useContext(ConfigurationContext);
    const {values: {year, brand}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const disabled = progress < 2 || year === undefined || brand === undefined;
    if (disabled) {
        return <BsFormSelect name={'version'} placeholder={t('series')} options={[]} disabled={disabled}/>
    } else {
        return <VersionApiSelect/>
    }
};
export default VersionSelect;
