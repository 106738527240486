import React, {useMemo} from "react";
import {StringParam, useQueryParams} from "use-query-params";
import usePrevious from "./usePrevious";

const useConfiguratorParams = () => {
    const [query, setQuery] = useQueryParams({
        year: StringParam,
        brand: StringParam,
        model: StringParam,
        version: StringParam,
        interior: StringParam,
        statusString: StringParam
    });

    const prevQuery = usePrevious(query);

    const progress = useMemo(() => {
        let progress = 0;
        const {
            year,
            brand,
            model,
            version,
            interior
        } = query;

        if (year && year !== "") {
            progress = 1;
        } else {
            return 0;
        }
        if (brand && brand !== "") {
            progress = 2;
        } else {
            return 1;
        }
        if (model && model !== "") {
            progress = 3;
        } else {
            return 2;
        }
        if (version && version !== "") {
            progress = 4;
        } else {
            progress = 3;
        }
        if (interior && interior !== "") {
            progress = 5;
        } else {
            progress = 4;
        }
        return progress;
    }, [query]);

    // eslint-disable-next-line
    const initialQuery = useMemo(() => query,[]);

    const vehicleId = React.useMemo(() => {
        if (query.interior){
            const {uvc, uvc_tie_breaker} = JSON.parse(query.interior);
            return `${uvc}-${uvc_tie_breaker}`;
        }
        return "";
    }, [query.interior]);

    const vehicleName = React.useMemo(() => {
        if (query.interior){
            const {name} = JSON.parse(query.interior);
            return `${name}`;
        }
        return "";
    }, [query.interior]);

    return {
        initialQuery,
        query,
        prevQuery,
        setQuery,
        progress,
        vehicleId,
        vehicleName,
        total: 5
    };
};

const ConfigurationContext = React.createContext({});
const ConfigurationProvider = ({children}) => {
    const values = useConfiguratorParams();
    return (
        <ConfigurationContext.Provider value={values}>
            {children}
        </ConfigurationContext.Provider>
    )
};

export {ConfigurationContext, ConfigurationProvider};