import React, {useMemo} from 'react';
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {VehicleYearResource} from "../../../rest/APIResources";
import {useFormState} from "react-final-form";
import {sortAlphabetical} from "./sorting";
import {useTranslation} from 'react-i18next';
import {reduceToName} from "./year-select";

import union from "lodash/union";

const BrandSelect = () => {
    const { t } = useTranslation();

    const {values: {year}} = useFormState({
        subscription: {values: true}
    });

    const data = useResource(VehicleYearResource.detailShape(), {year});

    const brands = useMemo(() => {
        if (year && year !== "") {
            const {savedVehicles, yearVehicles} = data;

            const yearListSaved = savedVehicles?.drilldown.class_list[0].year_list.filter(current => current.name === year);
            const yearListApi = yearVehicles?.drilldown.class_list[0].year_list.filter(current => current.name === year);

            let saved = [];
            let api = [];
            if (yearListSaved) {
                if (yearListSaved[0]) {
                    saved = yearListSaved[0].make_list.reduce(reduceToName, []);
                }
            }
            if (yearListApi) {
                if (yearListApi[0]) {
                    api = yearListApi[0].make_list.reduce(reduceToName, []);
                }
            }
            return union(saved, api).sort(sortAlphabetical);
        }
        return []
    }, [data, year]);

    return (
        <BsFormSelect name={'brand'} placeholder={t('manufacturer')} options={brands}/>
    );
};
export default BrandSelect;
