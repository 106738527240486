import React, {useContext, useEffect, useRef} from 'react';
import {useForm, useFormState, FormSpy} from "react-final-form";
import {ConfigurationContext} from "../../components/configuration-utils";
import {CustomerVehicleContext} from "../../components/customer-vehicles";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

const OnFormStateChange = ({name, children}) => {
    const prev = useRef();
    return <FormSpy onChange={({values}) => {
        const val = values[name];
        if (typeof children === "function") {
            children({value: val, previous: prev.current})
        }
        prev.current = val;
    }} subscription={{values: true}}/>;
};

const filterEmptyOrUndefined = (values) => {
    if (values) {
        const keys = Object.keys(values);
        return keys.filter(key => (values[key] !== undefined && values[key] !== "")).reduce((current, next) => {
            return {...current, [next]: values[next]}
        }, {});
    }
    return null;
};

const FormStateListener = () => {
    const {
        query,
        setQuery
    } = useContext(ConfigurationContext);

    const {
        selected,
        setSelected
    } = useContext(CustomerVehicleContext);

    const form = useForm();

    useEffect(() => {
        const _selected = filterEmptyOrUndefined(selected ? selected.query : null);
        if (_selected) {
            form.initialize(_selected);
        } else {
            if (setSelected)
                setSelected(null);
        }
        // eslint-disable-next-line
    }, [selected]);

    useEffect(() => {
        if (process.env.REACT_APP_MODE === "FE") {
            const localQuery = window.localStorage.getItem("query");
            try {
                if (isEmpty(filterEmptyOrUndefined(query))) {
                    if (localQuery) {
                        const _q = JSON.parse(localQuery);
                        form.initialize(_q);
                        setQuery(_q);
                    }
                }
            } catch {

            }
        }
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_MODE === "FE") {
            window.localStorage.setItem('query', JSON.stringify(query));
        }
    }, [query]);

    const getQueryVariables = (url) => {
        let params = {};
        const parser = document.createElement('a');
        parser.href = url;
        const query = parser.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    // update url on every select change
    const onChange = ({values}) => {
        // remove the status string if the vehicle is switched
        if (values.statusString !== "") {
            try {
                const queryVariables = getQueryVariables(window.location.href);
                if (queryVariables) {
                    if (queryVariables.interior !== values.interior) {
                        values.statusString = "";
                    }
                }
            } catch {

            }
        }
        setQuery(values);
        if (typeof setSelected === "function") {
            setSelected((current) => {
                if (current) {
                    const _selected = filterEmptyOrUndefined(current ? current.query : null);
                    const _values = filterEmptyOrUndefined(values);
                    if (!isEqual(_selected, _values)) {
                        return null;
                    }
                }
                return current;
            });
        }
    };
    useFormState({
        onChange: onChange,
        subscription: {values: true}
    });

    return (
        <React.Fragment>
            <OnFormStateChange name="year">
                {({value, previous}) => {
                    if (previous && previous !== value && !selected) {
                        form.change('interior', '');
                        form.change('version', '');
                        form.change('model', '');
                        form.change('brand', '');
                    }
                }}
            </OnFormStateChange>
            <OnFormStateChange name="brand">
                {({value, previous}) => {
                    if (previous && previous !== value && !selected) {
                        form.change('interior', '');
                        form.change('version', '');
                       form.change('model', '');
                    }
                }}
            </OnFormStateChange>
            <OnFormStateChange name="model">
                {({value, previous}) => {
                    if (previous && previous !== value && !selected) {
                        form.change('interior', '');
                        form.change('version', '');
                    }
                }}
            </OnFormStateChange>
            <OnFormStateChange name="version">
                {({value, previous}) => {
                    if (previous && previous !== value && !selected) {
                        form.change('interior', '');
                    }
                }}
            </OnFormStateChange>
        </React.Fragment>
    );
};

export default FormStateListener;