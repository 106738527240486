import React, {useMemo} from 'react';
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {VehicleYearResource} from "../../../rest/APIResources";
import {useFormState} from "react-final-form";
import {sortNumerical} from "./sorting";
import {useTranslation} from 'react-i18next';

import union from "lodash/union";

export const reduceToName = ((previousValue, currentValue) => {
    previousValue.push(currentValue.name);
    return previousValue;
});

const YearSelect = () => {
    const {values: {year}} = useFormState({
        subscription: {values: true}
    });

    const data = useResource(VehicleYearResource.detailShape(),{ year });
    const { t } = useTranslation();

    const years = useMemo(() => {
        const {savedVehicles, yearVehicles} = data;
        const yearSaved = savedVehicles?.drilldown.class_list[0].year_list
            .reduce(reduceToName, []) ?? [];
        const yearApi = yearVehicles?.drilldown.class_list[0].year_list
            .reduce(reduceToName, []) ?? [];

        return union(yearSaved, yearApi).sort(sortNumerical);
    }, [data]);

    return (
        <BsFormSelect name={'year'} placeholder={t('year')} options={years}/>
    );
};
export default YearSelect;
