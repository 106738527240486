import React, {useContext, useMemo} from 'react';
import {ConfigurationContext} from "../../../components/configuration-utils";
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {MakeModelResource} from "../../../rest/APIResources";
import {useFormState} from "react-final-form";
import {sortByObjectKey} from "./sorting";
import {useTranslation} from 'react-i18next';
import union from "lodash/union";
import unionBy from "lodash/unionBy";

const filterVehicles = (vehiclesAll, model, version) => {
    if (typeof vehiclesAll?.model_list !== "string") {
        const series = vehiclesAll?.model_list.filter(current => current.name === model)[0];
        if (series) {
            const style = series.series_list.filter(current => current.name === version)[0];
            if (style) {
                return style.style_list.map(({uvc, uvc_tie_breaker, name}) => ({value: {uvc,  uvc_tie_breaker, name}, label: name}));
            }
        }
    }
};

const filterVehiclesAlternative = (vehiclesAll, model) => {
    if (vehiclesAll?.model_list !== 'empty') {
        const series = vehiclesAll?.model_list.filter(current => current.name === model)[0];
        if (series) {
            return series.series_list;
        }
    } else {
        return [];
    }
};

const InteriorApiSelect = () => {
    const {values: {year, brand, model, version}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const data = useResource(MakeModelResource.detailShape(), {year, brand});

    const interiors = useMemo(() => {
        const {savedVehicles, yearVehicles} = data;
        if (model && model !== "" && version && version !== "") {
            const modelSaved = filterVehicles(savedVehicles, model, version);
            const modelApi = filterVehicles(yearVehicles, model, version);
            return unionBy(modelSaved, modelApi, 'label').sort(sortByObjectKey('label'));
        }
        if (model && model !== "") {
            let seriesSaved = filterVehiclesAlternative(savedVehicles, model);
            let seriesApi = filterVehiclesAlternative(yearVehicles, model);
            const series = union(seriesSaved, seriesApi);

            if (series.some(c => c.name !== "")) return [];

            let s = series.filter(current => current.name === "")[0];
            if (s) {
                return s.style_list
                    .reduce(((previousValue, currentValue) => {
                        previousValue.push(currentValue);
                        return previousValue;
                    }), [])
                    .map(({uvc, uvc_tie_breaker, name}) => ({value: {uvc,  uvc_tie_breaker, name}, label: name}))
                    .sort(sortByObjectKey('label'));
            }
        }
        return [];
    }, [data, model, version]);

    return <BsFormSelect name={'interior'} placeholder={t('style')} options={interiors}/>
};

const InteriorSelect = () => {
    const {progress} = useContext(ConfigurationContext);
    const {values: {year, brand}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const disabled = progress < 2 || year === undefined || brand === undefined;
    if (disabled) {
        return <BsFormSelect name={'interior'} placeholder={t('style')} options={[]} disabled={disabled}/>
    } else {
        return <InteriorApiSelect/>
    }
};
export default InteriorSelect;
