import React, {useContext, useMemo} from 'react';
import {ConfigurationContext} from "../../../components/configuration-utils";
import BsFormSelect from "../../../components/bs-form-select";
import {useResource} from "rest-hooks";
import {MakeModelResource} from "../../../rest/APIResources";
import {useFormState} from "react-final-form";
import {sortNumerical} from "./sorting";
import {useTranslation} from 'react-i18next';
import {reduceToName} from "./year-select";
import union from "lodash/union";

const ModelApiSelect = () => {
    const {values: {year, brand}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const makeModel = useResource(MakeModelResource.detailShape(), {year, brand});

    const models = useMemo(() => {
        const {savedVehicles, yearVehicles} = makeModel;

        let modelSaved = [];
        if (typeof savedVehicles?.model_list !== "string") {
            modelSaved = savedVehicles?.model_list
                .reduce(reduceToName, []) ?? [];
        }
        let modelApi = [];

        if (typeof yearVehicles?.model_list !== "string") {
            modelApi = yearVehicles?.model_list
                .reduce(reduceToName, []) ?? [];
        }

        return union(modelSaved, modelApi).sort(sortNumerical);

    }, [makeModel]);

    return <BsFormSelect name={'model'} placeholder={t('model')} options={models}/>
};

const ModelSelect = () => {
    const {progress} = useContext(ConfigurationContext);
    const {values: {year, brand}} = useFormState({
        subscription: {values: true}
    });
    const { t } = useTranslation();
    const disabled = progress < 2 || year === undefined || brand === undefined;
    if (disabled) {
        return <BsFormSelect name={'model'} placeholder={t('model')} options={[]} disabled={disabled}/>
    } else {
        return <ModelApiSelect/>
    }
};
export default ModelSelect;
