import {AbstractInstanceType, MutateShape, ReadShape, RequestOptions, Resource, SchemaDetail} from "rest-hooks";
import {apiUrl} from "./resolveAPIUrl";

interface StyleList {
    readonly uvc: string;
    readonly uvc_tie_breaker: string;
    readonly name: string;
}
interface SeriesList {
    readonly style_list?: Array<StyleList>;
    readonly name: string;
}
interface ModelList {
    readonly series_list?: Array<SeriesList>;
    readonly name: string;
}
interface MakeList {
    readonly model_list?: Array<ModelList>;
    readonly name: string;
}
interface YearList {
    readonly make_list: Array<MakeList>;
    readonly name: string;
}
interface ClassList {
    readonly year_list: Array<YearList>;
    readonly name: string;
}
export interface Drilldown {
    readonly class_list: Array<ClassList>;
}

class VehicleYearResource extends Resource {
    readonly savedVehicles: {drilldown: Drilldown | null} | null = null;
    readonly yearVehicles: {drilldown: Drilldown | null} | null = null;
    readonly year: string = "";

    pk() {
        return this.year;
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000, // one hour
        };
    }

    static getKey() {
        return 'VehicleYearResource';
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: { year?: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'vehicleSearch');
        if (urlParams && urlParams.year) {
            // url.searchParams.append('year', urlParams.year);
        }
        return url.href;
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, { year?: string }> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async function(params: { year?: string }, body?: Readonly<object | string>)  {
                let response = await superShape.fetch(params, body);
                response.year = params.year ? params.year : 'all' ;
                return response;
            },
        };
    }
}

const getModelList = (yearList: ClassList, params: {year: any, brand: any}) => {
    let makeList = yearList ? yearList.year_list.filter((current: YearList) => current.name === params.year).find((r:any) => r !== undefined) : undefined;
    let modelList = makeList ? makeList.make_list.filter((current: MakeList) => current.name === params.brand).find((r:any) => r !== undefined) : undefined;
    return modelList ? modelList.model_list : 'empty';
};

class MakeModelResource extends Resource {
    readonly savedVehicles: {model_list: Array<ModelList> | null} | null = null;
    readonly yearVehicles: {model_list: Array<ModelList> | null} | null = null;
    readonly year: string = "";
    readonly brand: string = "";

    pk() {
        return this.year + this.brand;
    }

    static getKey() {
        return 'MakeModelResource';
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000, // one hour
        };
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: { year: string, brand: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'vehicleSearch');
        if (urlParams?.year && urlParams?.brand) {
            const {year, brand} = urlParams;
            url.searchParams.append('year', year);
            url.searchParams.append('make', brand);
            return url.href;
        }
        throw new Error('year and brand is required');
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, { year: string, brand: string }> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: { year: string, brand: string }, body?: Readonly<object>) => {
                let response = await superShape.fetch(params, body);
                if (response.length === 0) {
                    // eslint-disable-next-line
                    throw {status: '404'};
                }

                let savedVehicles = response.savedVehicles?.drilldown?.class_list.find((r:any) => r !== undefined);
                let yearVehicles = response.yearVehicles?.drilldown?.class_list.find((r:any) => r !== undefined);

                if (response.savedVehicles) {
                    response.savedVehicles.model_list = getModelList(savedVehicles, params);
                }
                if (response.yearVehicles) {
                    response.yearVehicles.model_list = getModelList(yearVehicles, params);
                }
                response.year = params.year;
                response.brand = params.brand;

                return response;
            },
        };
    }
}

interface OptionLogicListItem {
    readonly sequence_number: number;
    readonly option_category: string;
    readonly primary_option: string;
    readonly secondary_option: string;
    readonly option_description: string;
    readonly price_line_description: string;
    readonly retail_amount: number;
    readonly invoice_amount: number;
    readonly discount_retail_amount: number;
    readonly discount_invoice_amount: number;
    readonly has_requires: boolean;
    readonly has_includes: boolean;
    readonly has_excludes: boolean;
    readonly status_code: string;
    readonly model_option_flag: boolean;
    readonly include_option_flag: boolean;
}
export interface OptionLogic {
    readonly option_list: Array<OptionLogicListItem>;
    readonly option_categories: Array<{readonly description: string}>;
    readonly option_invoice_amount: number;
    readonly option_retail_amount: number;
}

type OptionLogicParams = {
    uvc: string,
    uvc_tie_breaker: string,
    optionAffected?: string,
    optChangeState?: string,
    chgOptStCode?: string,
    includeCategories?: string,
    statusString?: string,
    option?: number,
    newStatus?: string
}

class OptionLogicResource extends Resource {
    readonly options: OptionLogic | null = null;
    readonly uvc: string = "";
    readonly uvc_tie_breaker: string = "";
    readonly optionAffected: string = "";
    readonly optChangeState: string = "";
    readonly chgOptStCode: string = "";
    readonly includeCategories: string = "";
    readonly status_string: string = "";

    pk() {
        return this.status_string !== "" ? this.status_string : "_empty_";
    }

    static getKey() {
        return 'OptionLogicResource';
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000,
        };
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: OptionLogicParams & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'optionLogic');
        if (urlParams) {
            const {uvc, uvc_tie_breaker, statusString} = urlParams;
            url.searchParams.append('vehicleId', `${uvc}-${uvc_tie_breaker}`);

            if (statusString) {
                url.searchParams.append('statusString', statusString);
            }
            return url.href;
        }
        throw new Error('missing params');
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, OptionLogicParams> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: OptionLogicParams, body?: Readonly<object>) => {
                const response = await superShape.fetch(params, body);
                response.uvc = params.uvc;
                response.uvc_tie_breaker = params.uvc_tie_breaker;
                if (response.options == null) {
                    throw response;
                }
                return response;
            },
        };
    }

    static optionLogicShape<T extends typeof Resource> (
        this: T,
    ): MutateShape<SchemaDetail<AbstractInstanceType<T>>, OptionLogicParams> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            type: 'mutate',
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: OptionLogicParams, body?: any) => {
                if (params.statusString) {
                    delete body.statusString;
                }
                // @ts-ignore
                const response = await this.fetch('post', this.url(params), body);
                response.uvc = params.uvc;
                response.uvc_tie_breaker = params.uvc_tie_breaker;
                return response;
            },
        }
    }
}

interface EquipmentList {
    readonly sub_category: string;
    readonly equipment_value: string;
}
interface CategoryList {
    readonly name: string;
    readonly equipment_list: Array<EquipmentList>;
}
export interface StandardEquipment {
    readonly category_list: Array<CategoryList>
}
class StandardEquipmentResource extends Resource {
    readonly std_equip: StandardEquipment | null = null;
    readonly uvc: string = "";
    readonly uvc_tie_breaker: string = "";

    pk() {
        return this.uvc + this.uvc_tie_breaker;
    }

    static getKey() {
        return 'StandardEquipmentResource';
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000, // one hour
        };
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: { uvc: string, uvc_tie_breaker: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'standardEquipment');
        if (urlParams) {
            const {uvc, uvc_tie_breaker} = urlParams;
            url.searchParams.append('vehicleId', `${uvc}-${uvc_tie_breaker}`);
            return url.href;
        }
        // if (urlParams) {
        //     const {uvc, uvc_tie_breaker} = urlParams;
        //     return `${window.configurator.apiUrl}/${uvc}${uvc_tie_breaker}/standard-equipment.json`;
        //     // to utilize mocking the api
        //     return `${window.configurator.apiUrl}/NewCarAPI/NewCarAPI/StandardEquipment/${uvc}/${uvc_tie_breaker}`;
        // }
        // since we're overriding the url() function we must keep the type the
        // same, which means we might not get urlParams
        throw new Error('missing params');
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, { uvc: string, uvc_tie_breaker: string }> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: { uvc: string, uvc_tie_breaker: string }, body?: Readonly<object>) => {
                const response = await superShape.fetch(params, body);
                response.uvc = params.uvc;
                response.uvc_tie_breaker = params.uvc_tie_breaker;
                return response;

                // return Promise.resolve<any>(
                //     {std_equip: StandardEquipmentData.std_equip, ...params}
                // )
            },
        };
    }

}
interface VehicleColor {
    readonly category: string;
    readonly primary_code: string;
    readonly secondary_code: string;
    readonly description: string;
    readonly retail?: number;
    readonly invoice?: number;
    readonly swatch_list: Array<string>
}
export interface VehicleColors{
    readonly color_list: Array<VehicleColor>;
}
class VehicleColorsResource extends Resource {
    readonly vehicle_colors: VehicleColors | null = null;
    readonly uvc: string = "";
    readonly uvc_tie_breaker: string = "";

    pk() {
        return this.uvc + this.uvc_tie_breaker;
    }

    static getKey() {
        return 'VehicleColorsResource';
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000, // one hour
        };
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: { uvc: string, uvc_tie_breaker: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'vehicleColors');
        if (urlParams) {
            const {uvc, uvc_tie_breaker} = urlParams;
            url.searchParams.append('vehicleId', `${uvc}-${uvc_tie_breaker}`);
            return url.href;
        }
        // if (urlParams) {
        //     const {uvc, uvc_tie_breaker} = urlParams;
        //
        //     return `${window.configurator.apiUrl}/${uvc}${uvc_tie_breaker}/vehicle-colors.json`;
        //
        //
        //     return `${window.configurator.apiUrl}/NewCarAPI/NewCarAPI/Color/${uvc}/${uvc_tie_breaker}`;
        // }
        // since we're overriding the url() function we must keep the type the
        // same, which means we might not get urlParams
        throw new Error('missing params');
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, { uvc: string, uvc_tie_breaker: string }> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: { uvc: string, uvc_tie_breaker: string }, body?: Readonly<object>) => {
                const response = await superShape.fetch(params, body);
                response.uvc = params.uvc;
                response.uvc_tie_breaker = params.uvc_tie_breaker;
                return response;
                // return Promise.resolve<any>(
                //     {vehicle_colors: VehicleColorsData.vehicle_colors, ...params}
                // )
            },
        };
    }

}
export interface VehiclePhoto {
    readonly file_contents?: string; //base64 jpg!
}
class VehiclePhotoResource extends Resource {
    readonly photo: VehiclePhoto | null = null;
    readonly uvc: string = "";
    readonly uvc_tie_breaker: string = "";

    pk() {
        return this.uvc + this.uvc_tie_breaker;
    }

    static getKey() {
        return 'VehiclePhotoResource';
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000, // one hour
        };
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: { uvc: string, uvc_tie_breaker: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'vehiclePhoto');
        if (urlParams) {
            const {uvc, uvc_tie_breaker} = urlParams;
            url.searchParams.append('vehicleId', `${uvc}-${uvc_tie_breaker}`);
            url.searchParams.append('size', 'large');
            return url.href;
        }
        // if (urlParams) {
        //     const {uvc, uvc_tie_breaker} = urlParams;
        //
        //     return `${window.configurator.apiUrl}/${uvc}${uvc_tie_breaker}/vehicle-photo.json`;
        //
        //     // to utilize mocking the api
        //     return `${window.configurator.apiUrl}/NewCarAPI/NewCarAPI/Photos/${uvc}?size=medium`;
        // }
        // since we're overriding the url() function we must keep the type the
        // same, which means we might not get urlParams
        throw new Error('missing params');
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, { uvc: string }> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: { uvc: string, uvc_tie_breaker: string }, body?: Readonly<object>) => {
                const response = await superShape.fetch(params, body);
                response.uvc = params.uvc;
                response.uvc_tie_breaker = params.uvc_tie_breaker;
                return response;
                // return Promise.resolve<any>(
                //     {photo: VehiclePhotoData.photo, ...params}
                // )
            },
        };
    }

}

export interface VehicleInfo {
    readonly new_vehicle_list: Array<{
        readonly effective_date: string;
        readonly vin: string;
        readonly uvc: string;
        readonly uvc_tie_breaker: string;
        readonly vinuvc: string;
        readonly model_year: string;
        readonly make: string;
        readonly model: string;
        readonly series: string;
        readonly style: string;
        readonly manufacturer: string;
        readonly vehicle_type: string;
        readonly marketing: string;
        readonly accel0to60: number;
        readonly msrp: number;
        readonly invoice: number;
        readonly equip_retail: number;
        readonly residual_12: number;
        readonly residual_24: number;
        readonly residual_36: number;
        readonly residual_48: number;
        readonly residual_60: number;
        readonly residual_72: number;
        readonly destination_list: Array<{
            readonly description: string;
            readonly type: string;
            readonly retail: number;
            readonly invoice: number;
        }>;
        readonly add_deduct_list: Array<{
            readonly description: string;
            readonly residual_12: number;
            readonly residual_24: number;
            readonly residual_36: number;
            readonly residual_48: number;
            readonly residual_60: number;
            readonly residual_72: number;
        }>;
        readonly major_change_list: Array<{
            readonly description: string;
        }>;
        readonly include_list: Array<{
            readonly code: string;
            readonly description: string;
        }>
    }>;
}
class VehicleInfoResource extends Resource {
    readonly new_vehicles: VehicleInfo | null = null;
    readonly uvc: string = "";
    readonly uvc_tie_breaker: string = "";

    pk() {
        return this.uvc + this.uvc_tie_breaker;
    }

    static getKey() {
        return 'VehicleInfoResource';
    }

    static getRequestOptions(): RequestOptions {
        return {
            dataExpiryLength: 60 * 60 * 1000, // one hour
        };
    }

    static url<T extends typeof Resource>(
        this: T,
        urlParams?: { uvc: string, uvc_tie_breaker: string } & Partial<AbstractInstanceType<T>>,
    ): string {
        const url = new URL(apiUrl);
        url.searchParams.append('_action', 'vehicleProperties');
        if (urlParams) {
            const {uvc, uvc_tie_breaker} = urlParams;
            url.searchParams.append('vehicleId', `${uvc}-${uvc_tie_breaker}`);
            return url.href;
        }
        // if (urlParams) {
        //     const {uvc, uvc_tie_breaker} = urlParams;
        //
        //     return `${window.configurator.apiUrl}/${uvc}${uvc_tie_breaker}/vehicle-infos.json`;
        //
        //     return `${window.configurator.apiUrl}/NewCarAPI/NewCarAPI/NewVehicle/UVC/${uvc}/${uvc_tie_breaker}`;
        // }
        // since we're overriding the url() function we must keep the type the
        // same, which means we might not get urlParams
        throw new Error('missing params');
    }

    static detailShape<T extends typeof Resource>(
        this: T,
    ): ReadShape<SchemaDetail<AbstractInstanceType<T>>, { uvc: string, uvc_tie_breaker: string }> {
        const superShape = super.detailShape();
        return {
            ...superShape,
            // @ts-ignore
            schema: superShape.schema,
            fetch: async (params: { uvc: string, uvc_tie_breaker: string }, body?: Readonly<object>) => {
                const response = await superShape.fetch(params, body);
                response.uvc = params.uvc;
                response.uvc_tie_breaker = params.uvc_tie_breaker;
                return response;
            },
        };
    }
}

export {
    VehicleYearResource,
    MakeModelResource,
    OptionLogicResource,
    StandardEquipmentResource,
    VehicleColorsResource,
    VehiclePhotoResource,
    VehicleInfoResource
};