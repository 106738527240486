import React, {useMemo, useState} from "react";

const useBackendFunctions = () => {
    const [currentVehicle, setCurrentVehicle] = useState({});
    const backendData = useMemo(() => ({
        factorB2B: currentVehicle.factorBaseB2b ? currentVehicle.factorBaseB2b : 1,
        factorB2C: currentVehicle.factorBaseB2c ? currentVehicle.factorBaseB2c : 1,
        factorEquipmentB2B: currentVehicle.factorEquipmentB2b ? currentVehicle.factorEquipmentB2b : 1,
        factorEquipmentB2C: currentVehicle.factorEquipmentB2c ? currentVehicle.factorEquipmentB2c : 1,
        lkw: currentVehicle.lkw ? currentVehicle.lkw : undefined,
        consumptionCity: currentVehicle.consumptionCity ? currentVehicle.consumptionCity : "16",
        consumptionOutsideCity: currentVehicle.consumptionOutsideCity ? currentVehicle.consumptionOutsideCity : "12",
        consumptionCombined: currentVehicle.consumptionCombined ? currentVehicle.consumptionCombined : "14",
        emissionClass: currentVehicle.emissionClass ? currentVehicle.emissionClass : "euro6a",
        co2EfficiencyClass: currentVehicle.co2EfficiencyClass ? currentVehicle.co2EfficiencyClass : "g",
        co2EmissionCombined: currentVehicle.co2EmissionCombined ? currentVehicle.co2EmissionCombined : "320",
        euronorm: currentVehicle.euronorm ? currentVehicle.euronorm : "euro4",
        dno: currentVehicle.vehicleFeatures,
        vehicleFeatures: currentVehicle.vehicleFeatures,
        exportVehicle: currentVehicle.exportVehicle,
        export: currentVehicle.export,
        specialSale: currentVehicle.specialSale,
        vehicleDoors: currentVehicle.vehicleDoors ? currentVehicle.vehicleDoors : "FOUR_OR_FIVE",
        fuelType: currentVehicle.fuelType,
        airbags: currentVehicle.airbags,
        vehicleClass: currentVehicle.vehicleClass,
        vehicleCategory: currentVehicle.vehicleCategory,
        vehicleColors: currentVehicle.vehicleColors,
        loginPid: currentVehicle.loginPid,
    }), [currentVehicle]);

    return {currentVehicle, setCurrentVehicle, ...backendData};
};

const BackendContext = React.createContext({});
const BackendProvider = ({children}) => {
    const values = useBackendFunctions();
    return (
        <BackendContext.Provider value={values}>
            {children}
        </BackendContext.Provider>
    )
};

export {BackendContext, BackendProvider}